<template>
  <admin-template
    :title="alterId ? 'alterar cor' : 'nova cor'"
    backlink="/admin/color"
  >
    <form>
      <fieldform text="nome">
        <el-input v-model="formData.name" ></el-input>
      </fieldform>

      <fieldform text="cor">
        <el-color-picker v-model="formData.code"></el-color-picker>
      </fieldform>

      <div class="submit">
        <el-button
          @click="submit"
          type="primary"
        >
          salvar
        </el-button>
      </div>
    </form>
  </admin-template>
</template>

<script>
import api from '@/services/api'
import adminTemplate from '@/components/admintemplate.vue'
import fieldform from '@/components/fieldform.vue'

export default {
  name: 'coupon-list',
  components: {
    adminTemplate,
    fieldform,
  },
  data() {
    return {
      formData: {
        name: null,
        code: '#409EFF',
      },
      alterId: this.$route.params.id || null
    }
  },
  methods: {
    submit() {
      const { formData } = this

      // ALTER DATA
      if (this.alterId) {
        api.put(`/backoffice/color/${this.alterId}`, {
          ...formData,
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'Cor alterada com sucesso!',
              type: 'success'
            });

           this.$router.push('/admin/color')
            return res
          })
      }
      // NEW DATA
      else {
        api.post(`/backoffice/color`, {
          ...formData,
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'Cor cadastrada com sucesso!',
              type: 'success'
            });

           this.$router.push('/admin/color')
            return res
          })
      }
    },
    getColorById(id) {
      api.get(`/backoffice/color/${id}`, {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({data}) => this.formData = data)
        .catch(err => console.log(err))
    },
    showMessage(payload) {
      this.$message(payload)
    }
  },

  mounted() {
    if (this.alterId) {
      this.getColorById(this.alterId)
    }
  },
}
</script>

<style>
form {
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 10px;
  margin-top: 23px;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
