<template>
  <admin-template
    :title="alterId ? 'alterar cupom' : 'novo cupom'"
    backlink="/admin/cupon"
  >
    <form>
      <fieldform text="código">
        <el-input v-model="formData.code" ></el-input>
      </fieldform>

      <fieldform text="tipo de cupom">
        <el-select
          v-model="formData.couponTypeId"
          placeholder="selecione"
        >
          <el-option
            v-for="item in couponTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </fieldform>

      <fieldform text="descrição">
        <el-input
          type="textarea"
          :rows="4"
          v-model="formData.description"
        ></el-input>
      </fieldform>

      <div class="col-3">

        <fieldform text="ativo">
          <el-switch
            v-model="formData.active"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </fieldform>

        <fieldform text="permitir recorrência">
          <el-switch
            v-model="formData.allowRecurrence"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </fieldform>

        <fieldform text="valor do desconto">
          <el-input
            type="number"
            :rows="4"
            v-model="formData.discountValue"
          ></el-input>
        </fieldform>
      </div>

      <div class="submit">
        <el-button
          @click="submit"
          type="primary"
        >
          salvar
        </el-button>
      </div>
    </form>
  </admin-template>
</template>

<script>
import api from '@/services/api'
import adminTemplate from '@/components/admintemplate.vue'
import fieldform from '@/components/fieldform.vue'

export default {
  name: 'coupon-list',
  components: {
    adminTemplate,
    fieldform,
  },
  data() {
    return {
      couponTypes: [{
        id: 1,
        name: 'carregando opções'
      }],
      formData: {
        couponTypeId: null,
        code: null,
        description: null,
        active: false,
        allowRecurrence: true,
        discountValue: null,
      },
      alterId: this.$route.params.id || null
    }
  },
  methods: {
    submit() {
      const { formData } = this

      // ALTER DATA
      if (this.alterId) {
        api.put(`/backoffice/coupon/${this.alterId}`, {
          ...formData,
          discountValue: Number(formData.discountValue),
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'Cupom alterado com sucesso!',
              type: 'success'
            });

           this.$router.push('/admin/cupon')
            return res
          })
      }
      // NEW DATA
      else {
        api.post(`/backoffice/coupon`, {
          ...formData,
          discountValue: Number(formData.discountValue),
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'Cupom cadastrado com sucesso!',
              type: 'success'
            });

           this.$router.push('/admin/cupon')
            return res
          })
      }
    },
    getCouponTypes() {
      api.get('/backoffice/couponType', {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({data}) => this.couponTypes = data)
        .catch(err => console.log(err))
    },
    getCouponById(id) {
      api.get(`/backoffice/coupon/${id}`, {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({data}) => this.formData = data)
        .catch(err => console.log(err))
    },
    showMessage(payload) {
      this.$message(payload)
    }
  },

  mounted() {
    if (this.alterId) {
      this.getCouponById(this.alterId)
    }
    this.getCouponTypes()
  },
}
</script>

<style scoped>
form {
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 10px;
  margin-top: 23px;
}

.col-3 {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr;
  column-gap: 20px;
}

.el-select {
  display: block;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
