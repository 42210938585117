<template>
  <admin-template
    title="cadastro de admin"
  >
    <form>
      <fieldform text="nome">
        <el-input v-model="formData.name"></el-input>
      </fieldform>

      <fieldform text="email">
        <el-input v-model="formData.email" ></el-input>
      </fieldform>

      <fieldform text="senha">
        <el-input v-model="formData.password" type="password"></el-input>
      </fieldform>

      <fieldform text="confirmar senha">
        <el-input v-model="formData.passwordConfirm" type="password"></el-input>
      </fieldform>

      <el-alert
        title="as senhas devem ser idênticas"
        type="error"
        v-if="passwordErrorMessage"
      >
      </el-alert>

      <div class="submit">
        <el-button
          @click="submit"
          type="primary"
        >
          criar admin
        </el-button>
      </div>
    </form>
  </admin-template>
</template>

<script>
import api from '@/services/api'
import fieldform from '@/components/fieldform.vue'
import adminTemplate from '@/components/admintemplate.vue'

export default {
  name: 'coupon-list',
  components: {
    adminTemplate,
    fieldform,
  },
  data() {
    return {
      formData: {
        name: null,
        email: null,
        password: null,
        passwordConfirm: null,
      },
      passwordErrorMessage: null,
    }
  },
  methods: {
    submit() {
      const { name, email, password, passwordConfirm } = this.formData
      if (password !== passwordConfirm) {
        this.passwordErrorMessage = true
      }
      else {
        api.post(`/backoffice/user`, {
          name,
          email,
          password
        },
          {
            headers: {
              'x-access-token': localStorage.token,
              'Content-Type': 'application/json',
            },
            mode: 'no-cors'
          })
          .then(res => {
            this.showMessage({
              message: 'Usuário Cadastrado com sucesso!',
              type: 'success'
            });

           this.$router.push('/admin/cupon')
            return res
          })
      }
    },
    showMessage(payload) {
      this.$message(payload)
    }
  }
}
</script>

<style scoped>
form {
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 10px;
  margin-top: 23px;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

form .el-alert {
  margin-top: 16px;
}
</style>
